import React, { useState } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { Formik, Form, Field } from 'formik'
import DefaultLink from '@/components/DefaultLink'
import Excerpt from '@/components/Excerpt'
import Arrow from '@/assets/Arrow'

const SearchPage = ({ data, location }: any) => {
  const params = new URLSearchParams(location.search)
  const s = params?.get('s')
  const [query, setQuery] = useState(null)
  const results = useFlexSearch(query || s, data[0]?.index, data[0]?.store)
  const overAllResults = results.length || '0'
  const sValue = query || s || ''

  return (
    <>
      <div className="pb-5">
        <div className="max-w-4xl mx-auto">
          <Formik
            initialValues={{ query: '' }}
            onSubmit={(values: any, { setSubmitting }) => {
              setQuery(values.query)
              setSubmitting(false)
            }}
          >
            <Form className="w-full md:flex items-center">
              <label htmlFor="searchpageform" className="w-[180px]">
                Etsi sivustolta
              </label>
              <div className="flex w-full mt-2 md:mt-0">
                <Field
                  id="searchpageform"
                  placeholder="hae"
                  className="bg-gray w-full border-none outline-none px-2 py-2"
                  name="query"
                />
                <button
                  className="px-5 sm:px-10 leading-6 pt-2 sm:pt-3 pb-1 sm:pb-2 shadow-xl text-lg tracking-wide bg-white ml-2 sm:ml-4 font-FuturaBol"
                  type="submit"
                >
                  Etsi
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      <div className="mb-24">
        <div className="max-w-4xl mx-auto flex flex-col sm:flex-row sm:mt-14 mt-6 mb-6 sm:mb-14 sm:text-2xl">
          <span className="font-FuturaBol">
            Etsi: <span className="font-FuturaBook">{sValue}</span>
          </span>
          <span className="sm:ml-auto font-FuturaBol">
            Tulokset: <span className="font-FuturaBook">{overAllResults}</span>
          </span>
        </div>
        <div className="max-w-4xl mx-auto">
          {results.map((result: any) => (
            <DefaultLink
              className="border-b border-primary my-2 py-6 wordbreak group flex flex-col sm:flex-row sm:pr-14 search-item"
              to={result.path}
              key={result.id}
            >
              <div className="w-[25px] h-[25px] shrink-0 grow-0 mr-10 mt-1 mb-2 sm:mb:0">
                <Arrow />
              </div>
              <div className="">
                <h2 className="text-blue mt-0 text-2xl sm:text-3xl group-hover:text-hovbrown transition-colors">
                  {result.title}
                </h2>
                {result.content && (
                  <Excerpt className="mt-2 font-FuturaBook text-lg sm:text-xl" content={result.content} number={200} />
                )}
              </div>
            </DefaultLink>
          ))}
        </div>
      </div>
    </>
  )
}
export default SearchPage
