import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import SearchPage from '@/components/SearchPage'

interface SearchData {
  data: {
    wp: any
    page: any
    menu: any
    quickmenu: any
  }
  location: any
}

const Haku = ({ data, location }: SearchData) => {
  const { menu, quickmenu } = data
  const { searchResults }: any = data

  const heroContent = {
    title: 'Hakutulokset',
  }

  return (
    <Layout nav={menu} hero={heroContent} quickNav={quickmenu}>
      <SearchPage data={searchResults?.nodes} location={location} />
    </Layout>
  )
}

export const SearchQuery = graphql`
  query search {
    searchResults: allLocalSearchPages {
      nodes {
        index
        store
      }
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
  }
`

export default Haku
